import styles from './anlagendaten.module.scss';
import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';

import StickyBar from 'components/StickyBar/StickyBar';
import api from 'api';
import React, { useEffect } from 'react';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import InputField from '@wienenergiegithub/ui-next/src/common/components/InputField/InputField';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';

import Heading from '@wienenergiegithub/ui-next/src/common/components/Heading/Heading';
import Content from '@wienenergiegithub/ui-next/src/common/components/Content/Content';
import Card from '@wienenergiegithub/ui-next/src/common/components/Card/Card';
import { useNotification } from '@wienenergiegithub/layout/src/common/modules/Notification/Context';

import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';
import { navigate } from 'gatsby';
import Textarea from '@wienenergiegithub/ui-next/src/common/components/Textarea/Textarea';

const FacilitySettings = ({ form }) => {
  return (
    <Card size="full" className={styles.formGrid}>
      <div className={styles.colFull}>
        <Heading size="h5" as="h2" className={styles.noMarginHeading}>
          Performance - {form.values.facility.name}
        </Heading>
      </div>

      <Content className={styles.rowLabel}>Name</Content>

      <InputField
        name={['facility', 'name']}
        type="text"
        className={styles.double}
        labelHidden
        label="Anlagenname"
        {...form}
      />

      <Content className={styles.rowLabel}>Kennlinie</Content>

      <InputField
        name={['facility', 'incline']}
        label="Steigung"
        type="number"
        labelHidden
        {...form}
      />
      <InputField
        name={['facility', 'offset']}
        label="Versatz"
        type="number"
        labelHidden
        {...form}
      />

      <Heading size="h5" as="h2" className={styles.noMarginHeading}>
        Grenzen
      </Heading>

      <div>
        <Heading size="h5" as="h2" className={styles.noMarginHeading}>
          Min
        </Heading>
      </div>
      <div>
        <Heading size="h5" as="h2" className={styles.noMarginHeading}>
          Max
        </Heading>
      </div>

      <Content className={styles.rowLabel}>Heizwert MJ/kg</Content>

      <InputField
        name={['facility', 'min_calorific_value']}
        type="number"
        labelHidden
        label="min calorific value"
        {...form}
      />
      <InputField
        name={['facility', 'max_calorific_value']}
        labelHidden
        label="max calorific value"
        type="number"
        {...form}
      />

      <Content className={styles.rowLabel}>Niederkalorisch in %</Content>

      <InputField
        name={['facility', 'min_low_waste']}
        type="number"
        labelHidden
        label="min low waste"
        {...form}
      />
      <InputField
        name={['facility', 'max_low_waste']}
        labelHidden
        label="min low waste"
        type="number"
        {...form}
      />

      <Content className={styles.rowLabel}>Systemmüllähnlich in %</Content>

      <InputField
        name={['facility', 'min_regular_waste']}
        type="number"
        labelHidden
        label="min regular waste"
        {...form}
      />
      <InputField
        name={['facility', 'max_regular_waste']}
        type="number"
        labelHidden
        label="max regular waste"
        {...form}
      />

      <Content className={styles.rowLabel}>Hochkalorisch in %</Content>

      <InputField
        name={['facility', 'min_high_waste']}
        type="number"
        labelHidden
        label="min high waste"
        {...form}
      />
      <InputField
        name={['facility', 'max_high_waste']}
        type="number"
        labelHidden
        label="max high waste"
        {...form}
      />

      <Content className={styles.rowLabel}>Sperrmüll in %</Content>

      <InputField
        name={['facility', 'min_bulk_waste']}
        tpe="number"
        labelHidden
        label="min bulk waste"
        {...form}
      />
      <InputField
        name={['facility', 'max_bulk_waste']}
        type="number"
        labelHidden
        label="max bulk waste"
        {...form}
      />

      <Content className={styles.rowLabel}>Höchstkalorisch in %</Content>

      <InputField
        name={['facility', 'min_highest_waste']}
        type="number"
        labelHidden
        label="min highest waste"
        {...form}
      />
      <InputField
        name={['facility', 'max_highest_waste']}
        type="number"
        labelHidden
        label="max highest waste"
        {...form}
      />
      <Textarea
        name={['facility', 'general_email']}
        label="Allgemeiner E-mail-Text"
        data-purpose="textarea-message"
        className={styles.colFull}
        {...form}
      />
    </Card>
  );
};

const AnlagenDatenPage = () => {
  const notifications = useNotification();

  const form = useFormState({
    values: null,
    onValidate: values => {
      const errors = {};
      if (!values.facility.name) {
        if (!errors.facility) {
          errors.facility = [];
        }
        if (!errors.facility.name) {
          errors.facility.name = {};
        }
        errors.facility.name = 'Name muss ausgefüllt werden';
      }
      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async values => {
      const id = (Math.random() + 1).toString(36).substring(7);
      try {
        await api.put(
          `/backend/api/facilities/${form.values.facility.id}`,
          values.facility,
        );
        notifications.add({
          title: 'Anlagendaten gespeichert',
          icon: 'check',
          id,
          type: 'success',
          handleClose: () => {
            // eslint-disable-next-line no-console
            console.log('close');
          },
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 3000);
      } catch (error) {
        notifications.add({
          title: 'Es ist ein Fehler aufgetreten',
          details: 'Bitte versuchen Sie es später erneut',
          icon: 'exclamation',
          id,
          type: 'danger',
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
  });

  useEffect(() => {
    const doRequests = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const facilityId = params.get('facility');

        // no facility id is not a valid state
        if (!facilityId) {
          navigate('/anlagen');
        }
        const {
          data: { data: facilityData },
        } = await api.get(`/backend/api/facilities/${facilityId}`);
        form.update('facility', facilityData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };
    doRequests();
  }, []);

  return (
    <Wrapper>
      <Layout
        breadcrumbs={[
          {
            to: '/anlagen',
            title: 'Anlagen',
          },
        ]}
        title={form.values?.facility?.name}
      >
        <Form {...form}>
          <StickyBar>
            <div />
            <FormSubmitButton as={Button} className={styles.submit} {...form}>
              Speichern
            </FormSubmitButton>
          </StickyBar>
          {form.values && (
            <Container className={styles.containerLayout}>
              <FacilitySettings form={form} />
            </Container>
          )}
        </Form>
      </Layout>
    </Wrapper>
  );
};

export default AnlagenDatenPage;
